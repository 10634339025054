import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const WordGroupsGame = ({ gameData, gameNo }) => {
  // Initialize all game state
  const [allHiddenWords] = useState(() =>
    Object.values(gameData).flatMap(group => group.hidden)
  );

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentGuesses, setCurrentGuesses] = useState(() =>
    Array(allHiddenWords.length).fill('')
  );
  const [guesses, setGuesses] = useState(() =>
    Array(allHiddenWords.length).fill([])
  );
  const [discoveredWords, setDiscoveredWords] = useState(() =>
    Array(allHiddenWords.length).fill('')
  );
  const [selectedWords, setSelectedWords] = useState(new Set());
  const [solvedConnections, setSolvedConnections] = useState([]);
  const [connectionAttempts, setConnectionAttempts] = useState(4);
  const [usedLetters, setUsedLetters] = useState(new Set());
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const guess = currentGuesses[currentWordIndex];
    const targetWord = allHiddenWords[currentWordIndex];
    if (!targetWord || guess.length !== targetWord.length) return;

    const puzzleGuesses = [...guesses[currentWordIndex]];
    if (puzzleGuesses.length >= 6) return;

    // Update guesses
    const newGuesses = [...guesses];
    newGuesses[currentWordIndex] = [...puzzleGuesses, guess];
    setGuesses(newGuesses);

    // Update used letters
    const newUsedLetters = new Set(usedLetters);
    guess.split('').forEach(letter => newUsedLetters.add(letter));
    setUsedLetters(newUsedLetters);

    // Check if guess is correct
    if (guess === targetWord) {
      const newDiscoveredWords = [...discoveredWords];
      newDiscoveredWords[currentWordIndex] = guess;
      setDiscoveredWords(newDiscoveredWords);
      setMessage('Word found! Try making a connection or find more words.');
    } else if (puzzleGuesses.length === 5) {
      const newDiscoveredWords = [...discoveredWords];
      newDiscoveredWords[currentWordIndex] = targetWord;
      setDiscoveredWords(newDiscoveredWords);
      setMessage(`Word revealed: ${targetWord}`);
    }

    // Clear current guess
    setCurrentGuesses(prev => {
      const newGuesses = [...prev];
      newGuesses[currentWordIndex] = '';
      return newGuesses;
    });
  };

  const toggleWordSelection = (word) => {
    if (!word) return;

    const newSelection = new Set(selectedWords);
    if (selectedWords.has(word)) {
      newSelection.delete(word);
    } else if (selectedWords.size < 4) {
      const isAlreadySolved = solvedConnections.some(conn =>
        conn.words.includes(word)
      );

      if (!isAlreadySolved) {
        newSelection.add(word);
      }
    }
    setSelectedWords(newSelection);

    if (newSelection.size === 4) {
      checkConnection(Array.from(newSelection));
    }
  };

  const checkConnection = (selectedWords) => {
    if (connectionAttempts <= 0) return;

    for (const [group, data] of Object.entries(gameData)) {
      const allGroupWords = [...data.visible, ...data.hidden];
      if (selectedWords.every(word => allGroupWords.includes(word))) {
        setSolvedConnections(prev => [...prev, {
          group,
          words: selectedWords,
          theme: data.theme,
          color: data.color
        }]);
        setMessage(`Correct! Theme: ${data.theme}`);
        setSelectedWords(new Set());
        return;
      }
    }

    setConnectionAttempts(prev => prev - 1);
    const attemptsLeft = connectionAttempts - 1;
    setMessage(`Incorrect connection. ${attemptsLeft} attempts remaining.`);
    setSelectedWords(new Set());

    if (attemptsLeft <= 0) {
      setGameOver(true);
      const unsolvedGroups = Object.entries(gameData)
        .filter(([group]) => !solvedConnections.some(conn => conn.group === group));
      setMessage('Game Over! The remaining connections were: ' +
        unsolvedGroups.map(([group, data]) => `${group} (${data.theme})`).join(', '));
    }
  };

  const isLetterCorrect = (letter, position) => {
    const targetWord = allHiddenWords[currentWordIndex];
    return targetWord[position] === letter;
  };

  const isLetterPresent = (letter) => {
    const targetWord = allHiddenWords[currentWordIndex];
    return targetWord.includes(letter);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (gameOver || !allHiddenWords[currentWordIndex]) return;

      if (e.key === 'Enter') {
        handleSubmit(e);
      } else if (e.key === 'Backspace') {
        setCurrentGuesses(prev => {
          const newGuesses = [...prev];
          newGuesses[currentWordIndex] = newGuesses[currentWordIndex].slice(0, -1);
          return newGuesses;
        });
      } else if (/^[a-zA-Z]$/.test(e.key)) {
        const targetLength = allHiddenWords[currentWordIndex].length;
        if (currentGuesses[currentWordIndex].length < targetLength) {
          setCurrentGuesses(prev => {
            const newGuesses = [...prev];
            newGuesses[currentWordIndex] = newGuesses[currentWordIndex] + e.key.toUpperCase();
            return newGuesses;
          });
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentGuesses, currentWordIndex, gameOver, allHiddenWords]);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-2xl mx-auto p-4 space-y-6">
        <div className="text-center space-y-2">
          <h1 className="text-2xl font-medium text-gray-900">Game #{gameNo}</h1>
          <p className="text-sm text-gray-600">
            Find words and make connections. {connectionAttempts} connection attempts remaining.
          </p>
          {message && (
            <p className="text-sm text-gray-800 bg-gray-200 p-2 rounded">
              {message}
            </p>
          )}
        </div>

        {/* Visible Words Grid */}
        <div className="grid grid-cols-4 gap-2">
          {Object.entries(gameData).flatMap(([group, data]) =>
            data.visible.map((word, i) => {
              const isSolved = solvedConnections.some(conn =>
                conn.group === group && conn.words.includes(word)
              );

              return (
                <button
                  key={`${group}-${i}`}
                  onClick={() => !isSolved && toggleWordSelection(word)}
                  disabled={isSolved}
                  style={isSolved ? { backgroundColor: data.color } : undefined}
                  className={`
                    p-4 rounded font-medium text-center transition-all
                    ${isSolved ? 'text-white' :
                      selectedWords.has(word)
                        ? 'bg-green-600 text-white border-green-600'
                        : 'bg-white text-gray-900 border border-gray-300'
                    }
                    ${selectedWords.has(word) ? 'ring-2 ring-offset-2' : ''}
                  `}
                >
                  {word}
                </button>
              );
            })
          )}
        </div>

        {/* Discovered Words Grid */}
        <div className="grid grid-cols-4 gap-2">
          {discoveredWords.map((word, i) => {
            if (!word) {
              return <div key={i} className="h-14 border border-gray-300 rounded" />;
            }

            const group = Object.entries(gameData).find(([_, data]) =>
              data.hidden.includes(allHiddenWords[i])
            )?.[0];

            const isSolved = solvedConnections.some(conn =>
              conn.group === group && conn.words.includes(word)
            );

            return (
              <button
                key={i}
                onClick={() => !isSolved && toggleWordSelection(word)}
                disabled={isSolved}
                style={isSolved ? { backgroundColor: gameData[group].color } : undefined}
                className={`
                  p-4 rounded font-medium text-center transition-all
                  ${isSolved ? 'text-white' :
                    selectedWords.has(word)
                      ? 'bg-green-600 text-white'
                      : 'bg-gray-600 text-white'
                  }
                `}
              >
                {word}
              </button>
            );
          })}
        </div>

        {/* Solved Connections */}
        {(gameOver || solvedConnections.length > 0) && (
          <div className="space-y-2">
            {solvedConnections.map(({group, words, theme, color}, i) => (
              <div
                key={i}
                className="p-3 rounded text-white text-sm"
                style={{ backgroundColor: color }}
              >
                {theme}: {words.join(', ')}
              </div>
            ))}
          </div>
        )}

        {/* Word Navigation */}
        {allHiddenWords.length > 0 && (
          <>
            <div className="flex items-center justify-center gap-4">
              <button
                onClick={() => currentWordIndex > 0 && setCurrentWordIndex(i => i - 1)}
                disabled={currentWordIndex === 0}
                className="p-2 rounded hover:bg-gray-200 text-gray-900 disabled:opacity-50"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <span className="font-medium text-gray-900">
                Word {currentWordIndex + 1} of {allHiddenWords.length}
              </span>
              <button
                onClick={() => currentWordIndex < allHiddenWords.length - 1 && setCurrentWordIndex(i => i + 1)}
                disabled={currentWordIndex === allHiddenWords.length - 1}
                className="p-2 rounded hover:bg-gray-200 text-gray-900 disabled:opacity-50"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </div>

            {/* Word Grid */}
            <div className="text-center text-sm text-gray-600">
              Find a {allHiddenWords[currentWordIndex].length}-letter word
            </div>

            <div className="space-y-2">
              {[...Array(6)].map((_, i) => {
                const guess = i === guesses[currentWordIndex]?.length
                  ? currentGuesses[currentWordIndex]
                  : guesses[currentWordIndex]?.[i] || '';
                const targetWord = allHiddenWords[currentWordIndex];

                return (
                  <div key={i} className="flex gap-1 justify-center">
                    {[...Array(targetWord.length)].map((_, j) => {
                      const letter = guess[j] || '';
                      const className = guess && guess.length === targetWord.length
                        ? isLetterCorrect(letter, j)
                          ? 'bg-green-600 text-white border-green-600'
                          : isLetterPresent(letter)
                            ? 'bg-gray-600 text-white border-gray-600'
                            : letter
                              ? 'bg-gray-300 text-white border-gray-300'
                              : 'bg-white border-gray-300'
                        : 'bg-white border-gray-300';

                      return (
                        <div
                          key={j}
                          className={`
                            w-14 h-14 flex items-center justify-center
                            font-bold text-lg rounded border
                            transition-all duration-300 ${className}
                          `}
                        >
                          {letter}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>

            {/* Virtual Keyboard */}
            <div className="space-y-1">
              {[
                ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
                ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
                ['ENTER', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '⌫']
              ].map((row, i) => (
                <div key={i} className="flex justify-center gap-1">
                  {row.map((key) => {
                    const isUsed = usedLetters.has(key);
                    const isInWord = allHiddenWords[currentWordIndex].includes(key);

                    const className = isUsed && isInWord
                      ? 'bg-green-600 text-white border-green-600 hover:bg-green-700'
                      : isUsed
                        ? 'bg-gray-600 text-white border-gray-600 hover:bg-gray-700'
                        : 'bg-gray-200 text-gray-900 border-gray-300 hover:bg-gray-300';

                    return (
                      <button
                        key={key}
                        onClick={() => {
                          if (key === 'ENTER') {
                            handleSubmit(new Event('submit'));
                          } else if (key === '⌫') {
                            setCurrentGuesses(prev => {
                              const newGuesses = [...prev];
                              newGuesses[currentWordIndex] = newGuesses[currentWordIndex].slice(0, -1);
                              return newGuesses;
                            });
                          } else if (currentGuesses[currentWordIndex]?.length < allHiddenWords[currentWordIndex]?.length) {
                            setCurrentGuesses(prev => {
                              const newGuesses = [...prev];
                              newGuesses[currentWordIndex] = (newGuesses[currentWordIndex] || '') + key;
                              return newGuesses;
                            });
                          }
                          }
                        }
                        disabled={gameOver}
                        className={`
                          ${key === 'ENTER' || key === '⌫' ? 'px-3 text-sm' : 'w-10'}
                          h-14 font-bold rounded border text-lg
                          disabled:opacity-50 disabled:cursor-not-allowed
                          transition-colors ${className}
                        `}
                      >
                        {key}
                      </button>
                    );
                  })}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WordGroupsGame;