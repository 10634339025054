import React, { useState } from 'react';
import { ChevronLeft } from 'lucide-react';
import WordGroupsGame from './WordGroupsGame';
import gamesData from './linklegames.json';

const LinkleGames = () => {
  const [selectedGame, setSelectedGame] = useState(null);

  if (selectedGame) {
    return (
      <div>
        <div className="max-w-2xl mx-auto p-4">
          <button
            onClick={() => setSelectedGame(null)}
            className="flex items-center gap-2 text-gray-900 hover:text-gray-600 transition-colors"
          >
            <ChevronLeft className="h-5 w-5" />
            Back to Games
          </button>
        </div>
        <WordGroupsGame
          gameData={gamesData[selectedGame].groups}
          gameNo={gamesData[selectedGame].gameNo}
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-2xl mx-auto p-4 space-y-6">
        <div className="text-center space-y-2">
          <h1 className="text-2xl font-medium text-gray-900">Linkle Games</h1>
          <p className="text-sm text-gray-600">
            Select a game to play
          </p>
        </div>

        <div className="space-y-3">
          {Object.entries(gamesData)
            .sort(([,a], [,b]) => new Date(b.date) - new Date(a.date))
            .map(([key, game]) => (
              <button
                key={key}
                onClick={() => setSelectedGame(key)}
                className="w-full p-4 bg-white rounded shadow text-left group hover:bg-gray-50 transition-colors"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <h2 className="font-medium text-gray-900 group-hover:text-gray-600">
                      {game.title}
                    </h2>
                    <p className="text-sm text-gray-600">
                      Game #{game.gameNo} • {new Date(game.date).toLocaleDateString()}
                    </p>
                  </div>
                  <ChevronLeft className="h-5 w-5 rotate-180 text-gray-600" />
                </div>
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LinkleGames;